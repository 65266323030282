import React from "react";
import { graphql } from "gatsby";
import Seo from "../../components/Seo";
import { Link } from "gatsby";
import Layout from "../../layouts/index";
import NFooter from "../../components/nFooter";
import chamil from "../../images/chamil.jpg";
import chamil2 from "../../images/chamil4.png";
import mvp from "../../images/Chamil-group.jpg";

const News = (props) => {
    return (
        <Layout bodyClass="page-news noteworthy">
            <Seo
                title="New Addition to Salesforce MVP Hall of Fame Sets Global Milestone"
                description="Chamil Madusanka, Head of Salesforce Practice at Australian-based IT firm iTelaSoft, has become the first ever Sri Lankan to be inducted into the Salesforce MVP Hall of Fame."
            />
            <div className="intro intro-banner" id="up">
                <div className="intro-banner-img">
                    <img alt="New Addition to Salesforce MVP Hall of Fame Sets Global Milestone" src={chamil} />
                </div>

                <div className="container container-narrow intro-title noteworthy-title">
                    <div className="row">
                        <div className="col-12">
                            <div className="date">NEWS | MARCH 26, 2024</div>
                            <h1>
                            New Addition to Salesforce MVP Hall of Fame Sets Global Milestone
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container blog-page MVP-page pt-4 mb-6">

                {/* <div className="img-section">
                    <div className="show-desk">
                        <div className="img-block">
                            <img alt="chamil" src={chamil2} />
                            <p><b>Chamil Madusanka</b></p>
                        </div>

                        <div className="text-block">
                            <p>Chamil Madusanka, Head of Salesforce Practice at Australian-based IT firm iTelaSoft, has become the first ever Sri Lankan to be inducted into the Salesforce MVP Hall of Fame.</p>
                        
                            <p>Chamil has been leading iTelaSoft’s Salesforce team at the company’s Sri Lankan branch since 2022 and has been supported by iTelaSoft in his continued contributions to the Salesforce community.</p>
                            
                            <p  className="hide-mobile">To be inducted, Salesforce Trailblazers (those that contribute to the community) have to be awarded the MVP title five separate times. To become an MVP, Trailblazers are self- or community-nominated and are then evaluated for their expertise, leadership, and generosity in helping others learn.</p>
                            
                            <p className="hide-mobile">As of today, less than 200 professionals across the globe hold these titles. And notably, Chamil was also the first Sri Lankan to be awarded the MVP title in 2019. With Chamil’s Hall of Fame status, iTelaSoft can bring even more value to clients, including higher priority on support, the ability to work and partner with leading organisations and software firms, and access to further certification and training opportunities.</p>              
                        </div>
                    </div>
                    <p  className="hide-desk">To be inducted, Salesforce Trailblazers (those that contribute to the community) have to be awarded the MVP title five separate times. To become an MVP, Trailblazers are self- or community-nominated and are then evaluated for their expertise, leadership, and generosity in helping others learn.</p>
                            
                    <p className="hide-desk">As of today, less than 200 professionals across the globe hold these titles. And notably, Chamil was also the first Sri Lankan to be awarded the MVP title in 2019. With Chamil’s Hall of Fame status, iTelaSoft can bring even more value to clients, including higher priority on support, the ability to work and partner with leading organisations and software firms, and access to further certification and training opportunities.</p>              

                </div> */}

                <img className="mvp-img" alt="mvp" src={mvp} />

                <br/>

                {/* Q1 */}
                <h5>Q: How did your journey with Salesforce begin?</h5>
            
                <p>A: My journey with Salesforce began unexpectedly when I attended an interview for a Java position but discovered the company was forming a Salesforce team instead.  
                In 2011, I became an associate Salesforce developer, diving into R&D and seeking guidance from the global community, particularly from experienced professionals in India.</p>

                <br/>

                {/* Q2 */}
                <h5>Q: And how did you mature with Salesforce?</h5>

                <p>A: Initially, I immersed myself in research and development to grasp the Salesforce platform, becoming one of the top contributors in Visualforce discussions within a year.  
                This caught the attention of Packt Publishing, leading to my authoring the ‘Visualforce Developer’s Guide’ in 2013, marking the start of my journey as an author. This opportunity spurred me to publish another book in 2015, and I also served as a technical reviewer for four additional books by Salesforce MVPs.</p>
                
                <br/>
                
                {/* Q3 */}
                <h5>Q: What motivated you to initiate the Sri Lankan Salesforce community?</h5>

                <p>A: When I began with Salesforce in Sri Lanka, it was largely unnoticed in the industry, and I felt isolated from fellow professionals. To foster connection, I created a LinkedIn group, which evolved into an official developer community with support from Salesforce HQ and Kavindra Patel. 
                We organised meetups, hackathons, and workshops, with a landmark hackathon in 2014. I represented Sri Lanka at Salesforce conferences in India and mentored newcomers, aiming to elevate the country’s presence globally.</p>

                <br/>

                {/* Q4 */}
                <h5>Q: So what special moments have you had in this Salesforce Journey?</h5>

                <p>A: My journey began with little awareness of community recognition, but in 2017/18, Salesforce featured Sri Lanka’s first Trailblazer story on Trailhead.  
                In 2019, I was honoured to become the first ever Sri Lankan Salesforce MVP, recognised for technical expertise and community contributions. I renewed my MVP status for the next five years. In 2024, I was inducted into the Salesforce MVP Hall of Fame - the first Sri Lankan to achieve this honour.</p>

                <br/>

                {/* Q5 */}
                <h5>Q: How did iTelaSoft become involved with Salesforce?</h5>

                <p>A: iTelaSoft, a Salesforce partner, specialises in implementing and customising solutions across Sri Lanka, the UK, and Australia.  
                As the head of Salesforce practice in Sri Lanka and India, I lead efforts to deliver tailored solutions leveraging Salesforce’s diverse product range. Salesforce, founded in 1999 as a CRM platform, has evolved into a comprehensive digital partner, offering solutions for various industries to improve business relationships, streamline processes, and increase profitability. 
                With acquisitions like vlocity, ExactTarget, Tableau and Slack, Salesforce provides a broad spectrum of applications, making it adaptable for businesses of all sizes, facilitating streamlined digital transformation and growth opportunities.</p>

                <br/>

                {/* Q6 */}
                <h5>Q: Your message to those embarking on any career path is...</h5>

                <p>A: Keep the passion alive, embrace every opportunity that comes your way, and never hesitate to lend a helping hand to those around you. For it is through supporting others that we truly unlock our own potential and leave a lasting impact on the world.</p>

                <br/>

                <p className="link">Source: <a href="https://online.fliphtml5.com/pgkmm/dpri/#p=170" rel="noreferrer noopener"
                    aria-label="New-Addition-to-Salesforce-MVP-Hall-of-Fame-Sets-Global-Milestone"
                    target="_blank"> LMD APRIL 2024 DIGITAL EDITION... </a>
                </p>

                <br/>

                <NFooter
                    prv1="/noteworthy/revolutionizing-the-coconut-industry-through-digital-EMP"
                    prv2="/noteworthy/revolutionizing-the-coconut-industry-through-digital-EMP"
                    next1="/noteworthy/iTelaSoft-joins-Fintech-Australia-as-an-ecosystem-partner"
                    next2="/noteworthy/iTelaSoft-joins-Fintech-Australia-as-an-ecosystem-partner"
                    titlel="Revolutionizing the Coconut Industry through Digital EMP"
                    titler="iTelaSoft joins Fintech Australia as an ecosystem partner"
                />
            </div>

        </Layout>
    );
};
export default News;